import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {MainLayout} from "../layout/MainLayout";
import {FloorplanSection, trackContactClicked} from "@renaissancerentals/renaissance-component";
import {PageProps} from "./Home";
import {NotFound} from "./NotFound";

export const FloorplanPage: React.FC<PageProps> = ({property, contact, isLoading}) => {
    const routerParam = useParams();
    const [showContactModal, setShowContactModal] = useState(false);
    const [showApplicationModal, setShowApplicationModal] = useState(false);

    const mapRef: React.Ref<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const handleRefToMap = () => {
        mapRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    useEffect(() => {
        console.log(routerParam.floorplanId);
    }, [routerParam]);
    return (
        <MainLayout isMainPage={false}
                    showApplicationModal={showApplicationModal} showContactModal={showContactModal}
                    handleRefToMap={handleRefToMap}
                    setShowApplicationModal={setShowApplicationModal} setShowContactModal={setShowContactModal}
                    contact={contact} property={property}>
            {isLoading ? <></> : routerParam.floorplanId ?
                <FloorplanSection
                    contactClickHandler={() => {
                        setShowContactModal(true);
                        trackContactClicked("scholars-rooftop");
                    }}
                    applyClickHandler={() => setShowApplicationModal(true)}
                    floorplanId={routerParam.floorplanId} locationRef={mapRef}
                    handleRefToLocation={handleRefToMap}/> : <NotFound/>
            }

        </MainLayout>
    );
}
