import React from 'react';
import './assets/HeroSection.scss';
import hero from "./assets/Hero.jpg";
import {Button} from "@contentmunch/muncher-ui";

export const HeroSection: React.FC<HeroSectionProps> = ({handleRefToFloorPlan}) => {
    return (
        <section className="section__hero">

            <img src={hero} alt="hero"/>
            <div className="hero--content">
                <h1>
                    love <span className="together">where you</span> live
                </h1>
                <p> Modern-Mediterranean 1 Bedroom Apartments with spacious, open layouts and a rooftop
                    patio
                </p>
                <Button variant="secondary" onClick={() => {
                    handleRefToFloorPlan()
                }}>Find your Rooftop Apt</Button>
            </div>
        </section>
    );
}

export interface HeroSectionProps {
    handleRefToFloorPlan: () => void;
}