import React from 'react';
import './assets/Amenities.scss';

export const AmenitiesSection: React.FC<AmenitiesSectionProps> = ({handleRefToMap}) => {

    return (
        <section className="section-amenities">
            <div className="container">
                <h2 className="heading"><span className="emphasized">Amenities</span></h2>
                <div className="section-amenities--content">
                    <div>
                        <h4>Our Community</h4>
                        <ul>
                            <li>Modern Mediterranean-inspired architecture</li>

                            <li>Conveniently located on the Northern edge of Downtown at the NE corner of <a
                                href="#location" onClick={handleRefToMap}>15th & Walnut</a></li>

                            <li>Rooftop Deck & Gardens</li>

                            <li>Community Gas Grill</li>

                            <li>Parking Garage + Street parking</li>

                            <li>Internet service included with rent</li>

                            <li>Water service included with rent</li>

                            <li>Free On-site trash & recycling</li>

                            <li>Cats Welcome (sorry, no dogs)</li>

                            <li>Rooftop & Garage with 24/7 Video Surveillance</li>

                            <li>Covered bike storage</li>

                            <li>On Bloomington Transit bus route</li>

                            <li>Managed by <a href="https://www.renaissancerentals.com">Renaissance Rentals</a>,
                                Bloomington's most trusted Local Landlord
                            </li>

                            <li>24 hour emergency maintenance</li>
                        </ul>


                    </div>
                    <div>
                        <h4>Our Apartments</h4>
                        <ul>
                            <li>Two distinct color schemes to choose from - light & airy Mediterranean or warm &
                                sophisticated Tuscan
                            </li>

                            <li>HE Washer / Dryer included</li>

                            <li>Waterproof Vinyl plank flooring throughout</li>

                            <li>9' ceilings</li>

                            <li>Galley-style kitchen w/ Stainless Steel kitchen appliances</li>

                            <li>Energy-star rated Dishwasher, Built-in Microwave, Fridge w/ Ice maker. glass top
                                stove
                            </li>

                            <li>Granite-look kitchen counters & bar top seating area</li>

                            <li>Double basin kitchen sink with pull down faucet & garbage disposal
                            </li>

                            <li>Brushed Nickel or Aged Bronze hardware throughout</li>

                            <li>Custom bathroom vanity with Framed Mirror</li>

                            <li>Large tub/shower with curved shower curtain rod</li>

                            <li>Ceiling fan with light in living room & bedroom</li>

                            <li>Large bedroom closet with organizational system</li>
                        </ul>
                        <h4>select apartments</h4>
                        <ul>
                            <li>Juliet balcony</li>

                            <li>90 additional sq. ft of living space</li>

                            <li>Pantry</li>

                            <li>Extra windows</li>

                            <li>Built-in desk</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export interface AmenitiesSectionProps {
    handleRefToMap: () => void;
}