import React from "react";
import {
    ApplicationModal,
    Contact,
    ContactModal,
    Footer,
    formatPhoneNumber,
    InfoHeader,
    PropertyDetails,
    trackContactClicked
} from "@renaissancerentals/renaissance-component";
import {MainHeader} from "../components/header/mainHeader/MainHeader";
import {FooterNav} from "../components/nav/FooterNav";

export const MainLayout: React.FC<MainLayoutProps> = (
    {
        isMainPage,
        property,
        contact,
        handleRefToFloorPlan,
        handleRefToMap,
        setShowContactModal, setShowApplicationModal, showApplicationModal, showContactModal,
        children
    }) => {

    const handleRefToTop = () => {
        window.scrollTo(0, 0);
    };
    return (<>
            <InfoHeader>
                <nav className="nav-info-header">
                    <h5>
                        call or text <a href={"tel:" + contact.phone}>&nbsp;{formatPhoneNumber(contact.phone)}</a>
                        <span className="main">
                        &nbsp;|&nbsp;<a href={"mailto:" + contact.email}>{contact.email}</a>&nbsp;|&nbsp;<a
                            href={isMainPage ? "#location" : "/#location"}
                            onClick={handleRefToMap}>{contact.address}</a>
                    </span>
                    </h5>
                </nav>
            </InfoHeader>
            <ContactModal showContactModal={showContactModal}
                          contactModalCloseHandler={() => setShowContactModal(false)}
                          conversionTrackingId1={property.conversionTrackingId1}
                          conversionTrackingId2={property.conversionTrackingId2}
                          to={property.email}
                          contactNumber={property.phone}
                          subject={"Message from " + property.name}
                          propertyId="scholars-rooftop"
            />
            <ApplicationModal showApplicationModal={showApplicationModal}
                              applicationModalCloseHandler={() => setShowApplicationModal(false)}
                              propertyName={property.name}
                              propertyEmail={property.email}
                              contactClickHandler={() => {
                                  setShowApplicationModal(false);
                                  setShowContactModal(true);
                                  trackContactClicked("scholars-rooftop");
                              }}/>
            <MainHeader isMainPage={isMainPage} setShowApplicationModal={setShowApplicationModal}
                        handleRefToTop={handleRefToTop}
                        handleRefToFloorPlan={handleRefToFloorPlan} contact={contact}
                        handleRefToMap={handleRefToMap} setShowContactModal={setShowContactModal}/>
            <main id="home">
                {children}
            </main>
            <Footer
                nav={<FooterNav setShowApplicationModal={setShowApplicationModal}
                                handleRefToFloorPlan={handleRefToFloorPlan}
                                handleRefToMap={handleRefToMap} setShowContactModal={setShowContactModal}
                                isMainPage={isMainPage} contact={contact}/>}/>
        </>
    );

}

export interface MainLayoutProps {
    showContactModal: boolean;
    showApplicationModal: boolean;
    setShowApplicationModal: (show: boolean) => void;
    setShowContactModal: (show: boolean) => void;
    property: PropertyDetails;
    handleRefToFloorPlan?: () => void;
    handleRefToMap?: () => void;
    isMainPage: boolean;
    contact: Contact;
    children?: any;
}
